<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('applications.search_applications')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('applications.search_applications')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="applications"
                :isLoading.sync="is_loading_applications"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('applications.applications')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'role'">
                        <p style="text-transform: capitalize;">
                            {{ props.row.relationships.role.data[0].attributes.name }}</p>
                    </div>
                    <div v-else-if="props.column.field === 'after'" class="td-after">
                            <Button v-if="$store.getters.hasPermission('update applications')" className="--secondary --outline --mini --big-text"
                                    :onclick="()=>toggleUpdateStatus(props.row.id)">
                                <font-awesome-icon :icon="['far', 'sync']"/>
                            </Button>
                        <Button v-if="$store.getters.hasPermission('read applications')"
                                className="--secondary --outline --mini --big-text"
                                :onclick="()=>$router.push({name: 'application', params: {id: props.row.id}})">
                            <font-awesome-icon :icon="['far', 'eye']"/>
                        </Button>
                    </div>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ApplicationsUpdateStatusModal from "../../components/applications/ApplicationUpdateStatusModal.vue";
import RequestPaymentModal from "../../components/applications/RequestPaymentModal";
export default {
    name: "applications-index",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('applications.id'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('applications.status'),
                field: this.computedStatus,
                sortable: false,
            },
            {
                label: this.$t('applications.type'),
                field: 'relationships.template.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('applications.name_of_applicant'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('applications.name_of_entity'),
                field: 'relationships.user.data.attributes.entity',
                sortable: false,
            },
            {
                label: this.$t('applications.contact_number'),
                field: 'relationships.user.data.attributes.contact_number',
                sortable: false,
            },
            {
                label: this.$t('applications.email'),
                field: 'relationships.user.data.attributes.email',
                sortable: false,
            },
            {
                label: this.$t('applications.date'),
                field: row => this.$moment(row.attributes.submitted_at).format('DD/MM/YY - HH:mm'),
                sortable: false,
            },
            // {
            //     label: this.$t('applications.payment'),
            //     field: 'attributes.payment',
            //     sortable: false,
            // },
        ];

        if (this.$store.getters.hasAnyPermission(['update applications', 'destroy applications']))
            columns.push({
                label: this.$t('applications.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            applications: [],
            is_loading_applications: false,
            totalRecords: null,
            serverParams: {sorting: [], filters: [{filter_by: 'submitted_at', filter_operator: '!=', filter_value: null}]},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        computedStatus(row) {
            if(row.attributes.status === 'vetting_in_progress')
                return this.$t('applications.vetting_in_progress');
            else if(row.attributes.status === 'require_information')
                return this.$t('applications.require_information');
            else if(row.attributes.status === 'completed')
                return this.$t('applications.completed');
            else if(row.attributes.status === 'rejected')
                return this.$t('applications.rejected');
            else
                return 'N/A'
        },
        toggleUpdateStatus(application_id) {
            this.$modal.show(
                ApplicationsUpdateStatusModal,
                {
                    application_id: application_id
                },
                {
                    name: 'application-update-status-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 450,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplications();
                    }
                }
            );
        },
        toggleRequestPayment() {
            this.$modal.show(
                RequestPaymentModal,
                {},
                {
                    name: 'request-payment-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplications();
                    }
                }
            );
        },
        toggleDelete(application) {
            this.$modal.show(
                ConfirmModal, {
                    title: this.$t('applications.delete_application'),
                    message: this.$t('applications.prompt_delete'),
                    confirmText: this.$t('delete'),
                    cancelText: this.$t('cancel'),
                    confirmClass: '--primary',
                    cancelClass: '--secondary --outline'
                },
                {
                    name: 'confirm-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true) {
                            this.is_deleting = true;
                            this.$axios.delete(`applications/${application.id}`)
                                .then(({}) => {
                                    this.is_deleting = false;
                                    this.getApplications();
                                })
                                .catch(e => {
                                    this.is_deleting = false;

                                    this.$notify({
                                        title: this.$t('error'),
                                        text: this.$larerror(e.response.data, this.$t('applications.error_delete')),
                                        type: 'error',
                                    });
                                });
                        }
                    }
                }
            );
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getApplications();
        },
        onSortChange(params) {
            const sorts = [];

            if (!params || !params.length) {
                this.updateParams({sorting: []});
                return this.getApplications();
            }

            params.forEach(p => {
                if (!p.type || p.type === 'none')
                    return;

                let sort_by;
                let sort_order = p.type;
                if (p.field === 'attributes.name')
                    sort_by = 'name';
                else if (p.field === 'attributes.email')
                    sort_by = 'email';
                else if (p.field === 'role')
                    sort_by = 'role';
                else
                    sort_by = p.field.split('.')[1];

                sorts.push({sort_order, sort_by})
            });

            this.updateParams({sorting: sorts});
            this.getApplications();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getApplications();
        },
        getApplications() {
            this.is_loading_applications = true;

            const encodedSorting = this.serverParams.sorting.map(f => btoa(JSON.stringify(f)));

            this.$axios.get('applications', {params: {...this.serverParams, sorting: encodedSorting}})
                .then(({data}) => {
                    this.applications = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_applications = false;
                })
                .catch(e => {
                    this.is_loading_applications = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.getApplications();
    },

    head() {
        return {
            title: {
                inner: this.$t('nav.applications')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
