<template>
    <div class="applications-generate-certificate-modal">
        <ModalContainer :title="$t('applications.generate_certificate')" identifier="application-generate-certificate-modal" :closable="true">
            <Form class="form" @submit="submit" :disabled="is_saving">
                <p>{{$t('applications.are_you_sure_generate_certificate')}}</p>

                <Button type="submit" class="--primary --small" :class="{spinner: is_saving}">
                    {{$t('confirm')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import Button from "../Button";

export default {
    name: "ApplicationGenerateCertificateModal",
    components: {Button, Form, ModalContainer},
    data() {
        return {
            is_saving: false,
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('application-generate-certificate-modal', status);
        },
        submit() {


            // this.$axios.patch(`applications/${this.application_id}`, payload).then(({data}) => {
            //     this.$notify({
            //         text: this.$t('applications.success_updated'),
            //         type: 'success',
            //     });
            //
            //     this.is_saving = false;
            //     this.close(true);
            // }).catch(e => {
            //     this.is_saving = false;
            //
            //     this.$notify({
            //         title: this.$t('error'),
            //         text: this.$larerror(e.response.data.errors, this.$t('applications.error_update')),
            //         type: 'error',
            //     });
            // });
        },
    },
}
</script>

<style lang="scss">
.applications-generate-certificate-modal {
    p {
        @apply text-center mt-2 mb-6;
    }
}
</style>
