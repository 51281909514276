<template>
    <div class="request-payment-modal">
        <ModalContainer :title="$t('applications.request_payment')" identifier="request-payment-modal" :closable="true">

            <div class="request-payment-modal-container">
                <div class="buttons-container">
                    <button class="button --primary --outline --small" :class="{active: percentage_option === '5%'}" @click="updatePercentage('5%')">5%</button>
                    <button class="button --primary --outline --small" :class="{active: percentage_option === '15%'}" @click="updatePercentage('15%')">15%</button>
                    <button class="button --primary --outline --small" :class="{active: percentage_option === 'custom'}" @click="updatePercentage('custom')">{{$t('applications.custom')}}</button>
                </div>

                <Form class="form" @submit="save" :disabled="is_saving">
                    <div class="form-row" v-if="percentage_option != 'custom'">
                        <FormInputSelect v-model="$v.details.currency.$model" identifier="currency"
                                         :label="$t('applications.currency')" :options="currency_options"
                                         :disabled="is_saving"
                                         :has-error="$v.details.currency.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.details.currency.required">
                                    {{$t('validation.x_is_required',{x: $t('applications.currency')})}}
                                </p>
                            </template>
                        </FormInputSelect>

                        <FormInputText v-model="$v.details.exchange_rate.$model" :label="`${$t('applications.exchange_rate')}`" @input="calculateAmount">
                            <template v-slot:errors>
                                <p v-if="!$v.details.exchange_rate.required">
                                    {{$t('validation.x_is_required',{x: $t('applications.exchange_rate')})}}
                                </p>
                            </template>
                        </FormInputText>
                    </div>

                    <div class="form-row">
                        <FormInputText v-model="$v.details.amount.$model" :label="`${$t('applications.amount')} (€)`"
                                       :disabled="percentage_option !== 'custom' || is_saving" :has-error="$v.details.amount.$error">
                            <template v-slot:errors>
                                <p v-if="!$v.details.amount.required">
                                    {{$t('validation.x_is_required',{x: $t('applications.amount')})}}
                                </p>
                            </template>
                        </FormInputText>
                    </div>

                    <div class="checkbox-container">
                        <input type="checkbox" id="send-certificate" v-model="send_certificate"/>
                        <label for="send-certificate">{{$t('applications.send_certificate_automatically')}}</label>
                    </div>

                    <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                        {{$t('applications.request')}}
                    </Button>
                </Form>
            </div>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";
    import FormGroupTwo from "@/components/form/FormGroupTwo.vue";
    import FormInputCheck from "@/components/form/FormInputCheck.vue";

    export default {
        name: "RequestPaymentModal",
        components: {
            FormInputCheck,
            FormGroupTwo, FormInputPassword, Button, FormInputSelect, FormInputText, Form, ModalContainer},
        props: {
            application_id: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                percentage_option: 'custom',
                currency_options: ['GBP', 'USD', 'EUR', 'PLN'],
                details: {
                    currency: null,
                    exchange_rate: null,
                    amount: null
                },
                value: 100,
                send_certificate: false,
                is_saving: false,
            }
        },
        validations: {
            details: {
                currency: { required },
                exchange_rate: { required },
                amount: { required },
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('request-payment-modal', status);
            },
            updatePercentage(percentage) {
                this.percentage_option = percentage;
                if(this.percentage_option === 'custom') {
                    this.details.amount = null;
                    this.details.exchange_rate = null;
                    this.details.currency = null;
                    this.$v.details.$reset();
                }
                else this.calculateAmount();
            },
            calculateAmount() {
                if (this.details.exchange_rate) this.details.amount = (this.value * parseFloat(this.details.exchange_rate) * parseFloat(this.percentage_option.slice(0, -1))/100).toFixed(2);
            },
            save() {
                this.$v.details.amount.$touch();
                if (this.$v.details.amount.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                this.$axios.patch(`applications/${this.application_id}`, {amount: this.details.amount}).then(({data}) => {
                    this.$notify({
                        text: this.$t('applications.payment_requested'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('applications.error_request_payment')),
                        type: 'error',
                    });
                });
            },
        }
    }
</script>

<style lang="scss">
    .request-payment-modal{
        @apply text-center items-center;

        .request-payment-modal-container {
            @apply mx-auto py-4;

            & > .buttons-container {
                @apply grid grid-cols-3 gap-x-4 mb-8  w-full max-w-xs mx-auto;

                & > .button {
                    &.active {
                        background-color: theme('colors.primary') !important;
                        color: theme('colors.white') !important;
                    }
                }
            }

            & > .form {
                @apply mx-auto;

                & > .form-row {
                    @apply flex flex-row mb-2 w-full max-w-xs mx-auto;

                    & > * {
                        &:first-of-type {
                            @apply w-1/3 mr-2;
                        }

                        &:last-of-type {
                            @apply ml-2 flex-1;
                        }
                    }
                }

                & > .checkbox-container {
                    @apply flex flex-row mx-auto my-8;

                    & > input {
                        @apply w-4;
                        accent-color: theme('colors.primary');
                    }

                    & > label {
                        @apply text-sm ml-2;
                    }
                }
            }
        }
    }
</style>
