import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import {i18n} from '../locale/i18n';
import ApplicationsIndex from "../views/applications/Index";
import ApplicantsIndex from "../views/applicants/Index";
import CertificatesIndex from "../views/certificates/Index";
import RolesIndex from "../views/roles/Index";
import UsersIndex from "../views/users/Index";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import Profile from "../views/Profile";
import Reset from "../views/Reset";
import Register from "../views/Register";
import Application from "../views/applications/Application.vue";

Vue.use(VueRouter);

const routes = [
    //TODO redirect to required page
    //TODO permissions
    {
        path: '/',
        redirect: '/applications'
    },
    {
        path: '/applications',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'applications-index',
                component: ApplicationsIndex,
                meta: {
                    auth: true,
                    all_permissions: ['read applications', 'update applications', 'destroy applications']
                },
            },
            {
                path: '/applications/:id',
                name: 'application',
                component: Application,
                meta: {
                    auth: true,
                    all_permissions: ['read applications', 'update applications', 'destroy applications']
                },
            },
        ]
    },
    {
        path: '/applicants',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'applicants-index',
                component: ApplicantsIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/certificates',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'certificates-index',
                component: CertificatesIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/roles',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'roles-index',
                component: RolesIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read roles'],
                },
            },
        ]
    },
    {
        path: '/users',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'users-index',
                component: UsersIndex,
                meta: {
                    auth: true,
                    // all_permissions: ['read users'],
                },
            },
        ]
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            auth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
