<template>
    <div class="field">
        <p class="field-label">{{field.attributes.name}}</p>

        <div class="date-picker" :class="{error: $v.value.$error}">
            <VueCtkDateTimePicker
                    :only-date="true" :auto-close="true" hint="Date" v-model="$v.value.$model"
                    label="Date" id="dateFromPicker" position="bottom"
                    :no-header="true" formatted="Do MMMM YYYY" output-format="YYYY-MM-DD"
                    :no-button-now="true" format="YYYY-MM-DD" color="#171628" button-color="#171628"
                    :min-date="minDate" :max-date="maxDate" :disabled="disabled"
                    @input="answerQuestion"
            />
        </div>

        <div v-if="$v.value.$error" class="form-errors">
            <p v-if="!$v.value.required">{{$t('forms.field_required')}}</p>
        </div>
    </div>
</template>

<script>
    import {requiredIf} from "vuelidate/lib/validators";

    export default {
        name: "TextBox",
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                value: null,
                is_answering_question: false
            }
        },
        computed: {
            minDate() {
                return this.field.attributes.options.min_date ? this.$moment(this.field.attributes.options.min_date).format('YYYY-MM-DD') : null;
            },
            maxDate() {
                return this.field.attributes.options.max_date ? this.$moment(this.field.attributes.options.max_date).format('YYYY-MM-DD') : null;
            },
        },
        validations() {
            return {
                value: {
                    required: requiredIf(function () {
                        return this.field.attributes.is_required
                    })
                }
            }
        },
        methods: {
            checkFieldValid(){
                this.$v.$touch();
                return !this.$v.$anyError;
            },
            answerQuestion() {
                if (this.disabled) return;

                this.$v.value.$touch();
                if (this.$v.value.$anyError) return;

                this.is_answering_question = true;

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, {value: this.value})
                    .then(({data}) => {
                        this.is_answering_question = false;
                    })
                    .catch(e => {
                        this.is_answering_question = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_answer_question'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.attributes.value)
                this.value = this.field.relationships.answer.data.attributes.value;
        }
    }
</script>