<template>
    <div class="applications-update-modal">
        <ModalContainer :title="$t('applications.set_appointment')" identifier="application-set-appointment-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputDateTime v-model="$v.appointment.date.$model" identifier="date"
                                   :label="$t('applications.date')" :minute-interval="5"
                                   :disabled="is_saving"
                                   :has-error="$v.appointment.date.$error" :min-date="todayDate">
                    <template v-slot:errors>
                        <p v-if="!$v.appointment.date.required">
                            {{ $t('validation.x_is_required', {x: $t('applications.date')}) }}
                        </p>
                    </template>
                </FormInputDateTime>

                <FormInputText v-model="$v.appointment.location.$model" :label="$t('applications.location')"
                               :disabled="is_saving" :use-textarea="true" :large-textarea="true"
                               :has-error="$v.appointment.location.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.appointment.location.required">
                            {{$t('validation.x_is_required',{x: $t('applications.location')})}}
                        </p>
                    </template>
                </FormInputText>

                <FormInputTags v-model="$v.appointment.emails.$model" :label="$t('applications.emails')"
                               :disabled="is_saving" :has-error="$v.appointment.emails.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.appointment.emails.required">
                            {{$t('validation.x_is_required',{x: $t('applications.emails')})}}
                        </p>
                    </template>
                </FormInputTags>

                <FormInputText v-model="$v.appointment.message.$model" :label="$t('applications.message')"
                               :disabled="is_saving" :use-textarea="true" :large-textarea="true"
                               :has-error="$v.appointment.message.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.appointment.message.required">
                            {{$t('validation.x_is_required',{x: $t('applications.message')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" class="--primary --small" :class="{spinner: is_saving}">
                    {{$t('send')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import {required} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputDateTime from "@/components/form/FormInputDateTime.vue";
    import FormInputTags from "@/components/form/FormInputTags.vue";

    export default {
        name: "ApplicationSetAppointmentModal",
        components: {FormInputTags, FormInputDateTime, Button, FormInputSelect, FormInputText, Form, ModalContainer},
        data() {
            return {
                appointment: {
                    date: null,
                    location: null,
                    emails: [],
                    message: null
                },
                is_saving: false,
            }
        },
        computed: {
            todayDate() {
                let date = new Date();
                return this.$moment(date).format('YYYY-MM-DD HH:mm');
            },
        },
        validations: {
            appointment: {
                date: {required},
                location: {required},
                emails: {required},
                message: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('application-set-appointment-modal', status);
            },
            save() {
                this.$v.appointment.$touch();
                if (this.$v.appointment.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                console.log(this.appointment);

                // this.$axios.patch(`applications/${this.application_id}`, payload).then(({data}) => {
                //     this.$notify({
                //         text: this.$t('applications.success_updated'),
                //         type: 'success',
                //     });
                //
                //     this.is_saving = false;
                //     this.close(true);
                // }).catch(e => {
                //     this.is_saving = false;
                //
                //     this.$notify({
                //         title: this.$t('error'),
                //         text: this.$larerror(e.response.data.errors, this.$t('applications.error_update')),
                //         type: 'error',
                //     });
                // });
            },
        },
    }
</script>
