<template>
    <loading-screen v-if="is_loading_questions"/>
    <div v-else class="step-wrapper">
        <div class="field-wrapper" v-for="field in questions">
            <heading v-if="field.attributes.type === 'heading'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <text-box v-if="field.attributes.type === 'text_box'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <text-area v-if="field.attributes.type === 'text_area'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <dropdown v-if="field.attributes.type === 'drop_down'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <date-picker v-if="field.attributes.type === 'date_picker'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <checkboxes v-if="field.attributes.type === 'checkboxes'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <file-upload v-if="field.attributes.type === 'file_upload'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <signature v-if="field.attributes.type === 'signature'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
            <dynamic-object v-if="field.attributes.type === 'object'" :ref="`field-${field.id}`" :field="field" :disabled="true"/>
        </div>
    </div>
</template>

<script>
import LoadingScreen from "@/components/LoadingScreen";
import Heading from "../fields/Heading.vue";
import TextBox from "../fields/TextBox";
import TextArea from "../fields/TextArea";
import Dropdown from "../fields/Dropdown";
import DatePicker from "../fields/DatePicker";
import Checkboxes from "../fields/Checkboxes";
import FileUpload from "../fields/FileUpload";
import Signature from "../fields/Signature";
import DynamicObject from "../fields/DynamicObject";

export default {
    name: "Step",
    components: {LoadingScreen, Heading, TextBox, TextArea, Dropdown, DatePicker, Checkboxes, FileUpload, Signature, DynamicObject},
    props: {
        step: {
            type: Object,
            required: true
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
         return {
             is_loading_questions: false,
             questions: [
                 // {
                 //     id: 1,
                 //     attributes: {
                 //         text: 'To be filled in by applicant/owner/representative. The applicant is to submit an object ID form per item.',
                 //         type: 'heading',
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 2,
                 //     attributes: {
                 //         text: 'Image of the imported cultural heritage item to be attached (one image from the front and details of significant identifying features, even if at the back, e.g. signatures, evident signs of deterioration/damage).',
                 //         type: 'heading',
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 3,
                 //     attributes: {
                 //         name: 'Name',
                 //         type: 'text_box',
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 4,
                 //     attributes: {
                 //         name: 'Consignee Address',
                 //         type: 'text_area',
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 5,
                 //     attributes: {
                 //         name: 'Country of Destination',
                 //         type: 'drop_down',
                 //         options: ['Malta', 'Poland', 'England'],
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 6,
                 //     attributes: {
                 //         name: 'Date of Movement/Export',
                 //         type: 'date_picker',
                 //         is_required: true,
                 //         options: {
                 //             min_date: '2023-01-03',
                 //             max_date: '2023-01-10',
                 //         }
                 //     },
                 // },
                 // {
                 //     id: 7,
                 //     attributes: {
                 //         name: 'Type of Export/Movement',
                 //         type: 'checkboxes',
                 //         is_required: true,
                 //         options: [
                 //             {
                 //                 id: 1,
                 //                 text: 'Permanent',
                 //             },
                 //             {
                 //                 id: 2,
                 //                 text: 'Temporary',
                 //             },
                 //             {
                 //                 id: 3,
                 //                 text: 'Re-Export/Movement'
                 //             }
                 //         ]
                 //     },
                 // },
                 // {
                 //     id: 8,
                 //     attributes: {
                 //         name: 'Attach Documents',
                 //         type: 'file_upload',
                 //         is_required: true,
                 //         multiple: false
                 //     }
                 // },
                 // {
                 //     id: 9,
                 //     attributes: {
                 //         name: 'Object',
                 //         type: 'object',
                 //         is_required: true,
                 //     }
                 // },
                 // {
                 //     id: 10,
                 //     attributes: {
                 //         name: 'Signature',
                 //         type: 'signature',
                 //         is_required: true,
                 //     }
                 // },
             ]
         }
    },
    methods: {
        getStepQuestions() {
            this.is_loading_questions = true;

            this.$axios.get(`applications/${this.$route.query.application ? this.$route.query.application : this.$route.params.id}/steps/${this.step.id}`)
                .then(({data}) => {
                    this.questions = data.data;
                    this.is_loading_questions = false;
                })
                .catch(e => {
                    this.is_loading_questions = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_step_questions')),
                        type: 'error',
                    });
                });
        },
        checkFieldsValid() {
            let valid = true;

            this.questions.forEach(q => {
                if (!this.$refs[`field-${q.id}`][0].checkFieldValid())
                    valid = false;
            });

            return valid;
        },
    },
    mounted() {
        this.getStepQuestions();
    }
}
</script>