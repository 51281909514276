<template>
    <div class="field">
        <p class="field-label">{{field.attributes.name}}</p>

        <multiselect v-model="$v.value.$model" :class="{error: $v.value.$error}" :options="field.attributes.options" :close-on-select="true"
                     :show-labels="false" :allow-empty="false" @input="answerQuestion" :disabled="disabled"/>

        <div v-if="$v.value.$error" class="form-errors">
            <p v-if="!$v.value.required">{{$t('forms.field_required')}}</p>
        </div>
    </div>
</template>

<script>
    import {requiredIf} from "vuelidate/lib/validators";
    import FormInputSelect from "@/components/form/FormInputSelect"

    export default {
        name: "Dropdown",
        components: {FormInputSelect},
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                value: null,
                is_answering_question: false
            }
        },
        validations() {
            return {
                value: {
                    required: requiredIf(function () {
                        return this.field.attributes.is_required
                    })
                }
            }
        },
        methods: {
            checkFieldValid(){
                this.$v.value.$touch();
                return !this.$v.value.$anyError;
            },
            answerQuestion(){
                if (this.disabled) return;

                this.$v.value.$touch();
                if (this.$v.value.$anyError) return;

                this.is_answering_question = true;

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, {value: this.value})
                    .then(({data}) => {
                        this.is_answering_question = false;
                    })
                    .catch(e => {
                        this.is_answering_question = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_answer_question'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.attributes.value)
                this.value = this.field.relationships.answer.data.attributes.value;
        }
    }
</script>