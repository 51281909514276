<template>
    <loading-screen v-if="is_loading_original"/>
    <div v-else-if="!is_loading_original && original && application" class="page-container">
        <Headbar>
            <template v-slot:left>
                <button class="back-button" @click="$router.push('applications-index')">
                    <font-awesome-icon :icon="['far','long-arrow-left']" />
                </button>
                <h1>
                    <span>{{application.attributes.reference}}</span>
                    {{application.relationships.template.data.id}}. {{application.relationships.template.data.attributes.name}}
                </h1>
            </template>
            <template v-slot:right>
                <div class="right-container">
                    <div class="row">
                        <p class="status-text"><span>{{$t('applications.status')}}:</span> {{computedStatus}}</p>
                        <Button v-if="$store.getters.hasPermission('update applications')" className="--secondary --outline --mini --big-text"
                                :content="$t('applications.generate_certificate')" v-tippy="{placement : 'bottom',  arrow: true}"
                                :onclick="()=>toggleGenerateCertificate()">
                            <font-awesome-icon :icon="['far', 'file-certificate']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('update applications') && application.relationships.template.data.id === 1"
                                className="--secondary --outline --mini --big-text"
                                :content="$t('applications.approve_application')" v-tippy="{placement : 'bottom',  arrow: true}"
                                :onclick="()=>toggleDigitalSignature()">
                            <font-awesome-icon :icon="['far', 'signature']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('update applications') && application.relationships.template.data.id !== 1"
                                className="--secondary --outline --mini --big-text"
                                :content="$t('applications.set_appointment')" v-tippy="{placement : 'bottom',  arrow: true}"
                                :onclick="()=>toggleSetAppointmentDate()">
                            <font-awesome-icon :icon="['far', 'calendar-day']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('update applications')" className="--secondary --outline --mini --big-text"
                                :content="$t('applications.update_status')" v-tippy="{placement : 'bottom',  arrow: true}"
                                :onclick="()=>toggleUpdateStatus()">
                            <font-awesome-icon :icon="['far', 'sync']"/>
                        </Button>
                        <Button v-if="$store.getters.hasPermission('update applications')" className="--secondary --outline --mini --big-text"
                                :content="$t('applications.request_payment')" v-tippy="{placement : 'bottom',  arrow: true}"
                                :onclick="()=>toggleRequestPayment()">
                            <font-awesome-icon :icon="['far', 'sack-dollar']"/>
                        </Button>
                    </div>

                    <Button className="--primary --mini --wider row">{{$t('save')}}</Button>
                </div>
            </template>
        </Headbar>
        <main>
            <div class="form-container">
                <p class="subtext">{{$t('applications.details_submitted_below')}}:</p>
                <div class="steps-nav-container">
                    <div class="step" :class="{active: active_step === i}" v-for="(step, i) in steps">
                        <p>{{i + 1}}</p>
                    </div>
                </div>

                <step v-for="(step, i) in steps" :step="step" :ref="`step-${i}`" v-show="i === active_step" :key="step.id"/>

                <div class="buttons-container">
                    <button class="button --secondary" v-if="active_step !== 0" @click="prevStep">{{$t('back')}}</button>
                    <button v-if="active_step !== steps.length-1" class="button --primary next-button" @click="nextStep">{{$t('next')}}</button>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";
import ApplicationUpdateStatusModal from "../../components/applications/ApplicationUpdateStatusModal.vue";
import RequestPaymentModal from "../../components/applications/RequestPaymentModal";
import LoadingScreen from "@/components/LoadingScreen.vue";
import Step from "@/components/form/Step.vue";
import ApplicationSetAppointmentModal from "@/components/applications/ApplicationSetAppointmentModal.vue";
import ApplicationDigitalSignatureModal from "@/components/applications/ApplicationDigitalSignatureModal.vue";
import ApplicationGenerateCertificateModal from "@/components/applications/ApplicationGenerateCertificateModal.vue";

export default {
    name: "applications-index-page",
    components: {Step, LoadingScreen, ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('applications.id'),
                field: 'attributes.reference',
                sortable: false,
            },
            {
                label: this.$t('applications.status'),
                field: this.computedStatus,
                sortable: false,
            },
            {
                label: this.$t('applications.type'),
                field: 'relationships.template.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('applications.name_of_applicant'),
                field: 'relationships.user.data.attributes.name',
                sortable: false,
            },
            {
                label: this.$t('applications.name_of_entity'),
                field: 'relationships.user.data.attributes.entity',
                sortable: false,
            },
            {
                label: this.$t('applications.contact_number'),
                field: 'relationships.user.data.attributes.contact_number',
                sortable: false,
            },
            {
                label: this.$t('applications.email'),
                field: 'relationships.user.data.attributes.email',
                sortable: false,
            },
            {
                label: this.$t('applications.date'),
                field: row => this.$moment(row.attributes.submitted_at).format('DD/MM/YY - HH:mm'),
                sortable: false,
            },
            // {
            //     label: this.$t('applications.payment'),
            //     field: 'attributes.payment',
            //     sortable: false,
            // },
        ];

        if (this.$store.getters.hasAnyPermission(['update applications', 'destroy applications']))
            columns.push({
                label: this.$t('applications.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            });

        return {
            columns: columns,
            original: null,
            application: null,
            active_step: 0,
            steps: [],
            totalRecords: null,
            serverParams: {sorting: [], filters: [{filter_by: 'submitted_at', filter_operator: '!=', filter_value: null}]},
            is_loading_original: false,
            is_loading_application_steps: false,
        }
    },
    computed: {
        computedStatus() {
            if(this.application.attributes.status === 'vetting_in_progress')
                return this.$t('applications.vetting_in_progress');
            else if(this.application.attributes.status === 'require_information')
                return this.$t('applications.require_information');
            else if(this.application.attributes.status === 'completed')
                return this.$t('applications.completed');
            else if(this.application.attributes.status === 'rejected')
                return this.$t('applications.rejected');
            else
                return 'N/A'
        },
    },
    methods: {
        toggleGenerateCertificate(application_id) {
            this.$modal.show(
                ApplicationGenerateCertificateModal,
                {
                    application_id: application_id
                },
                {
                    name: 'application-generate-certificate-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplications();
                    }
                }
            );
        },
        toggleDigitalSignature(application_id) {
            this.$modal.show(
                ApplicationDigitalSignatureModal,
                {
                    application_id: application_id
                },
                {
                    name: 'application-digital-signature-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplications();
                    }
                }
            );
        },
        toggleSetAppointmentDate() {
            this.$modal.show(
                ApplicationSetAppointmentModal,
                {},
                {
                    name: 'application-set-appointment-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplication();
                    }
                }
            );
        },
        toggleUpdateStatus() {
            this.$modal.show(
                ApplicationUpdateStatusModal,
                {
                    application_id: this.$route.params.id
                },
                {
                    name: 'application-update-status-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    width: 600,
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplication();
                    }
                }
            );
        },
        toggleRequestPayment() {
            this.$modal.show(
                RequestPaymentModal,
                {
                    application_id: this.$route.params.id
                },
                {
                    name: 'request-payment-modal',
                    adaptive: true,
                    resizable: true,
                    height: 'auto',
                    scrollable: true,
                    classes: 'modal',
                }, {
                    'before-close': (e) => {
                        if (e.params === true)
                            this.getApplications();
                    }
                }
            );
        },
        nextStep() {
            this.active_step++;
            window.scrollTo(0, 0);
        },
        prevStep() {
            this.active_step--;
            window.scrollTo(0, 0);
        },
        getApplication() {
            this.is_loading_original = true;

            this.$axios.get(`applications/${this.$route.params.id}`)
                .then(({data}) => {
                    this.original = data.data;
                    this.application = data.data;
                    this.is_loading_original = false;
                })
                .catch(e => {
                    this.is_loading_original = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('applications.error_retrieve_application')),
                        type: 'error',
                    });
                });
        },
        getApplicationSteps() {
            this.is_loading_application_steps = true;

            this.$axios.get(`applications/${this.$route.params.id}/steps`)
                .then(({data}) => {
                    this.steps = data.data;

                    this.is_loading_application_steps = false;
                })
                .catch(e => {
                    this.is_loading_application_steps = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_application_steps')),
                        type: 'error',
                    });
                });
        }
    },
    async mounted() {
        await this.getApplication();
        this.getApplicationSteps();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.applications')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    h1 {
        @apply text-base;

        @screen 2xl {
            @apply text-3xl;
        }

        & > span {
            @apply mr-4 pr-6 border-r-2 border-primary;
        }
    }

    .right-container {
        @apply flex flex-row flex-wrap mx-auto w-full justify-center;

        @screen lg {
            @apply w-auto;
        }

        .status-text {
            @apply text-sm py-2 pr-4 border-r-2 border-primary;

            & > span {
                @apply font-bold;
            }
        }

        & > .row {
            @apply w-full mt-6 mx-0 flex flex-row justify-center;

            @screen sm {
                @apply w-auto mx-1;
            }

            @screen lg {
                @apply my-auto;
            }

            & > * {
                @apply mx-1;

                &:first-child {
                    @apply ml-0 mr-4;
                }

                &:last-child {
                    @apply mr-0;
                }
            }

        }
    }

    .back-button {
        & > svg {
            @apply text-primary text-3xl;
        }
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    main {
        & > .form-container {
            @apply w-full max-w-3xl;

            @screen lg {
                @apply w-1/2;
            }

            & > .subtext {
                @apply font-bold;
            }

            & > .step-wrapper {
                @apply -mx-4;

                @screen md {
                    @apply mx-0;
                }
            }

            & > .steps-nav-container {
                @apply flex flex-row my-8;

                @screen md {
                    @apply px-0;
                }

                @screen xl {
                    @apply my-12;
                }

                & > .step {
                    @apply w-9 h-9 mr-8 bg-grey-lighter border-2 border-grey rounded-full flex flex-wrap;

                    &.active {
                        @apply border-primary bg-white;
                    }

                    & > p {
                        @apply m-auto text-secondary font-semibold;
                    }
                }
            }

            & > .buttons-container {
                @apply my-8 px-4 flex flex-row justify-between w-full;

                @screen md {
                    @apply px-0 max-w-3xl;
                }

                & > .next-button {
                    @apply ml-auto;
                }
            }
        }
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply mr-3;

            &:last-child {
                @apply mr-0;
            }
        }
    }
}
</style>
