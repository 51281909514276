<template>
    <div class="roles-create-modal">
        <ModalContainer :title="$t('roles.role_details')" identifier="roles-create-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_saving">
                <FormInputText v-model="$v.role.name.$model" identifier="name" :label="$t('roles.name')"
                               :disabled="is_saving" :has-error="$v.role.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.role.name.required">
                            {{ $t('validation.x_is_required', {x: $t('roles.name')}) }}
                        </p>
                    </template>
                </FormInputText>
                <FormInputSelect v-model="$v.role.permissions.$model" identifier="permissions"
                                 :label="$t('roles.permissions')" :options="permissionOptions"
                                 :disabled="is_saving" :has-error="$v.role.permissions.$error" track-by="id"
                                 display-label="name" :multiple="true" class="select">
                    <template v-slot:errors>
                        <p v-if="!$v.role.permissions.required">
                            {{ $t('validation.x_are_required', {x: $t('roles.permissions')}) }}
                        </p>
                    </template>
                </FormInputSelect>
                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{ $t('save') }}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import FormInputText from "../form/FormInputText";
import FormInputSelect from "../form/FormInputSelect";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "RolesCreateModal",
    components: {Button, FormInputSelect, FormInputText, Form, ModalContainer},
    data() {
        return {
            role: {
                name: null,
                permissions: [],
            },
            permissionOptions: [],
            is_saving: false,
            is_loading_permissions: false,
        }
    },
    validations: {
        role: {
            name: {required},
            permissions: {required},
        }
    },
    methods: {
        close(status) {
            this.$modal.hide('roles-create-modal', status);
        },
        save() {
            this.$v.role.$touch();
            if (this.$v.role.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            this.$axios.post(`roles`, {
                name: this.role.name,
            }).then(({data}) => {
                // sync permissions
                this.$axios.put(`roles/${data.data.id}/permissions/sync`, {
                    permissions: this.role.permissions.map(p=>p.id),
                }).then(({}) => {
                    this.$notify({
                        text: this.$t('roles.success_created'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('permissions.error_attach')),
                        type: 'error',
                    });
                });
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('roles.error_create')),
                    type: 'error',
                });
            });
        },
        getPermissions() {
            this.is_loading_permissions = true;
            
            this.$axios.get('permissions/list')
                .then(({data}) => {
                    this.permissionOptions = data.data.map(p => ({id: p.id, name: p.attributes.name}));
                    this.is_loading_permissions = false;
                })
                .catch(e => {
                    this.is_loading_permissions = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('permissions.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },

    mounted() {
        this.getPermissions();
    }
}
</script>


<style lang="scss" scoped>
.select {
    @apply mb-5;
}
</style>