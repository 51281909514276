<template>
    <div class="input-group" :class="{error: hasError}">
        <label v-if="label" :for="identifier">{{label}}</label>
        <vue-input-tag v-model="value" :placeholder="placeholder" :validate="validate" @input="(v)=>$emit('input', v)" :class="{error: hasError}"/>

        <FormErrors v-if="hasError">
            <slot name="errors"></slot>
        </FormErrors>
    </div>
</template>

<script>
import FormErrors from "./FormErrors";

export default {
    name: "FormInputTags",
    components: {FormErrors},
    props: {
        value: {
            type: Array,
        },
        identifier: {
            type: String,
            default: null,
        },
        label: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null
        },
        validate: {
            type: String,
            default: 'email'
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false
        },
        largeTextarea: {
            type: Boolean,
            default: false,
        },
        borderColor: {
            type: String,
            default: 'grey-light'
        }
    }
}
</script>

<style lang="scss" scoped>
.input-group {
    @apply w-full flex flex-col items-start mb-5;

    label {
        @apply text-sm text-black font-bold mb-1;
    }
}
</style>