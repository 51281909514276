<template>
    <div class="field">
        <p class="field-label">{{field.attributes.name}}</p>

        <div class="signature-container">
            <vue-perfect-signature v-if="!disabled" ref="signature" class="signature" :w="'320px'" :h="'150px'" :strokeOptions="strokeOptions" :disabled="disabled" @onEnd="save"/>
            <img v-else :src="value">

            <div class="buttons-container" v-if="!disabled">
                <button @click="clear">
                    <font-awesome-icon :icon="['fal','times']"/>
                </button>
            </div>
        </div>

        <div v-if="$v.value.$error" class="form-errors">
            <p v-if="!$v.value.required">
                {{$t('forms.field_required')}}
            </p>
        </div>
    </div>
</template>

<script>
    import {requiredIf} from "vuelidate/lib/validators";
    import _ from 'lodash';

    export default {
        name: "Signature",
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                strokeOptions: {
                    size: 2,
                    thinning: 0.5,
                    smoothing: 0.5,
                    streamline: 0.5
                },
                value: null,
                is_answering_question: false,
                is_uploading_signature: false
            }
        },
        validations() {
            return {
                value: {
                    required: requiredIf(function () {
                        return this.field.attributes.is_required
                    })
                }
            }
        },
        methods: {
            save() {
                this.value = this.$refs.signature?.toDataURL();
                this.answerQuestion();
            },
            clear() {
                this.$refs.signature?.clear();
                this.value = null;
            },
            checkFieldValid(){
                this.$v.$touch();
                return !this.$v.$anyError;
            },
            answerQuestion() {
                this.is_uploading_signature = true;

                let payload = {
                    value: this.value
                };

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, payload)
                    .then(({data}) => {
                        this.answer_id = data.data.id;
                        this.is_uploading_signature = false;
                    })
                    .catch(e => {
                        this.is_uploading_signature = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_upload_signature'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.attributes.value) {
                this.value = this.field.relationships.answer.data.attributes.value;
                this.$refs.signature?.fromDataURL(this.value);
            } else {
                let signature = this.$refs.signature.$el;
                signature.setAttribute('style', 'width: 320px; height: 150px; touch-action: none');
                signature.setAttribute('width', '320px');
                signature.setAttribute('height', '150px');
            }
        },
        created() {
            this.save = _.debounce(this.save, 500)
        }
    }
</script>