export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        applications: 'Applications',
        applicants: 'Applicants',
        certificates: 'Certificates',
        reports: 'Reports',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        user_account: 'User Account',
        personal_information: 'Personal Information',
        address_information: 'Address Information',
        change_password: 'Change Password',
        success_updated: 'Your profile has been updated',
        error_update: 'Failed to update profile'
    },
    forms: {
        start_the_process: 'Start the process!',
        title: 'Title',
        add_another_object_id: 'Add another Object ID',
        subject: 'Subject (brief description)',
        dimensions: 'Dimensions (In the case of framed works, specify if dimensions are with or without frame)',
        materials_and_techniques: 'Materials and techniques',
        inscriptions_or_markings: 'Inscriptions or markings (e.g. signatures, distinguishing signs of deterioration/damage, labels)',
        date_or_period: 'Date or period',
        maker: 'Maker/artist',
        existing_inventory_accession_number: 'Existing inventory/accession number (if applicable)',
        upload_images_of_item: 'Upload images of the item (of good quality, including inscriptions or markings)',
        upload_proof_of_purchase: 'Upload proof of purchase',
        upload_proof_of_provenance: 'Upload proof of provenance',
        your_application_has_been_submitted: 'Your application has been submitted!',
        choose_files: 'Choose File/s',
        error_retrieve: 'Failed to get forms',
        error_retrieve_form: 'Failed to get form',
        error_retrieve_application: 'Failed to get application',
        error_retrieve_application_steps: 'Failed to get application steps',
        error_retrieve_step_questions: 'Failed to get step questions',
        error_answer_question: 'Failed to answer question',
        error_submit_application: 'Failed to submit application',
        error_upload_file: 'Failed to upload file',
        error_upload_signature: 'Failed to upload signature',
        error_remove_file: 'Failed to remove file',
        error_download_file: 'Failed to download file',
        field_required: 'This field is required',
        select_at_least_one: 'Select at least one of the above',
        file_size_too_big: 'File size is too big',
    },
    applications: {
        applications: 'applications',
        add_application: 'Add Application',
        edit_application: 'Edit Application',
        search_applications: 'Search applications',
        update_status: 'Update Status',
        id: 'ID',
        status: 'Status',
        vetting_in_progress: 'Vetting In Progress',
        require_information: 'Require Information',
        completed: 'Completed',
        rejected: 'Rejected',
        type: 'Type',
        name_of_applicant: 'Name of Applicant',
        name_of_entity: 'Name of Entity',
        contact_number: 'Contact Number',
        email_address: 'Email Address',
        email: 'Email',
        date: 'Date',
        payment: 'Payment',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        set_appointment: 'Set Appointment',
        location: 'Location',
        emails: 'Email/s',
        message: 'Message',
        digital_signature: 'Digital Signature',
        kindly_sign_below: 'Kindly sign below',
        request_payment: 'Request Payment',
        are_you_sure_request_payment: 'Are you sure you want to request the pending payment?',
        generate_certificate: 'Generate Certificate',
        are_you_sure_generate_certificate: 'Are you sure you want to generate the certificate and send via email?',
        approve_application: 'Approve Application',
        amount: 'Amount',
        request: 'Request',
        custom: 'Custom',
        currency: 'Currency',
        exchange_rate: 'Exchange Rate',
        send_certificate_automatically: 'Send certificate automatically after payment have been completed',
        payment_requested: 'Payment has been requested',
        prompt_delete: 'Are you sure you want to delete this application?',
        details_submitted_below: 'Details submitted below',
        success_created: 'Application has been created',
        success_updated: 'Application has been updated',
        error_create: 'Failed to create application',
        error_update: 'Failed to update application',
        error_delete: 'Failed to delete application',
        error_retrieve: 'Failed to retrieve applications',
        error_retrieve_application: 'Failed to retrieve application',
        error_retrieve_statuses: 'Failed to retrieve statuses',
        error_request_payment: 'Failed to request payment',
        application_details: 'Application Details',
        update_application: 'Update Application',
        delete_application: 'Delete Application',
    },
    applicants: {
        applicants: 'applicants',
        add_applicant: 'Add Applicant',
        edit_applicant: 'Edit Applicant',
        search_applicants: 'Search applicants',
        name: 'Name',
        email: 'Email',
        contact_number: 'Contact Number',
        entity: 'Entity',
        email_address: 'Email Address',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this applicant?',
        success_created: 'Applicant has been created',
        success_updated: 'Applicant has been updated',
        error_create: 'Failed to create applicant',
        error_update: 'Failed to update applicant',
        error_delete: 'Failed to delete applicant',
        error_retrieve: 'Failed to retrieve applicants',
        applicant_details: 'Applicant Details',
        update_applicant: 'Update Applicant',
        delete_applicant: 'Delete Applicant',
    },
    certificates: {
        certificates: 'Certificates',
        add_certificate: 'Add Certificate',
        edit_certificate: 'Edit Certificate',
        search_certificates: 'Search certificates',
        application_id: 'Application ID',
        type: 'Type',
        name_of_applicant: 'Name of Applicant',
        name_of_entity: 'Name of Entity',
        date: 'Date',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this certificate?',
        success_created: 'Certificate has been created',
        success_updated: 'Certificate has been updated',
        error_create: 'Failed to create certificate',
        error_update: 'Failed to update certificate',
        error_delete: 'Failed to delete certificate',
        error_retrieve: 'Failed to retrieve certificates',
        certificate_details: 'Certificate Details',
        update_certificate: 'Update Certificate',
        delete_certificate: 'Delete Certificate',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search users',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        account_created: 'Account successfully created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password!',
        failed_signup: 'Signup Failed',
    },
    localities: {
        select_locality: 'Select locality',
        error_retrieve: 'Failed to retrieve localities',
    },
    cancel: 'Cancel',
    delete: 'Delete',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    print: 'Print',
    send: 'Send',
    confirm: 'Confirm',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    next: 'Next',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
}
