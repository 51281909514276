<template>
    <div class="page-container">
        <Headbar>
            <template v-slot:right>
                <Search class="search-desktop" :placeholder="$t('certificates.search_certificates')" @search="search"/>
                <button class="btn-search-mobile btn-icon-only"
                        @click="headbarExpanded = headbarExpanded === 'search' ? null : 'search'">
                    <font-awesome-icon v-if="headbarExpanded === 'search'" :icon="['far', 'times']"/>
                    <font-awesome-icon v-else :icon="['far', 'search']"/>
                </button>
            </template>
            <template v-slot:expanded>
                <div class="headbar-expanded-container">
                    <Search v-if="headbarExpanded === 'search'" :placeholder="$t('certificates.search_certificates')" @search="search"/>
                </div>
            </template>
        </Headbar>
        <main>
            <vue-good-table
                mode="remote"
                styleClass="vgt-table vgt-custom"
                :columns="columns"
                :rows="certificates"
                :isLoading.sync="is_loading_certificates"
                :search-options="{
                    enabled: false,
                }"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    dropdownAllowAll: false,
                    perPage: 15,
                    perPageDropdownEnabled: false,
                    rowsPerPageLabel: $t('x_per_page', {x: $t('certificates.certificates')}),
                }"
                :sort-options="{
                  enabled: true,
                  multipleColumns: true,
                }"
                :totalRows="totalRecords"
                @on-page-change="onPageChange">
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'after'" class="td-after">
                        <Button className="--secondary --outline --mini --big-text"
                                :onclick="()=>downloadCertificate(props.row.id)">
                            <font-awesome-icon :icon="['fal', 'download']"/>
                        </Button>
                    </div>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>
            </vue-good-table>
        </main>
    </div>
</template>

<script>
import Headbar from "../../components/headbar/Headbar";
import Search from "../../components/Search";
import Button from "../../components/Button";
import Select from "../../components/form/Select";
import ConfirmModal from "../../components/modal/ConfirmModal";

export default {
    name: "certificates-index",
    components: {ConfirmModal, Select, Button, Search, Headbar},
    data: function () {
        const columns = [
            {
                label: this.$t('certificates.application_id'),
                field: 'attributes.application_id',
                sortable: false,
            },
            {
                label: this.$t('certificates.type'),
                field: 'attributes.type',
                sortable: false,
            },
            {
                label: this.$t('certificates.name_of_applicant'),
                field: 'attributes.applicant_name',
                sortable: false,
            },
            {
                label: this.$t('certificates.name_of_entity'),
                field: 'attributes.entity_name',
                sortable: false,
            },
            {
                label: this.$t('certificates.date'),
                field: this.dateFn,
                sortable: false,
            },
            {
                label: this.$t('certificates.actions'),
                field: 'after',
                tdClass: 'td-after',
                sortable: false
            }
        ];

        return {
            columns: columns,
            certificates: [],
            is_loading_certificates: false,
            totalRecords: null,
            serverParams: {sorting: []},
            searchTerm: null,
            is_deleting: false,
            headbarExpanded: false
        }
    },
    methods: {
        dateFn(row) {
            return this.$moment(row.attributes.date).format('DD/MM/YYYY HH:mm');
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.getCertificates();
        },
        removeParam(param) {
            this.$delete(this.serverParams, param);
        },
        search(searchTerm) {
            this.searchTerm = searchTerm;

            if (searchTerm && searchTerm.length)
                this.updateParams({term: searchTerm});
            else this.removeParam('term');

            this.getCertificates();
        },
        downloadCertificate(certificate_id) {

        },
        getCertificates() {
            this.is_loading_certificates = true;

            this.$axios.get('certificates', {params: {...this.serverParams}})
                .then(({data}) => {
                    this.certificates = data.data;
                    this.totalRecords = data.meta.total;
                    this.is_loading_certificates = false;
                })
                .catch(e => {
                    this.is_loading_certificates = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('certificates.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getCertificates();
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.certificates')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    .headbar-expanded-container {
        @apply mt-4 flex flex-col gap-y-4 items-center;

        @screen md {
            @apply hidden;
        }
    }

    .select-dropdown {
        @apply min-w-40;
    }

    .btn-search-mobile, .btn-filter-mobile {
        @apply block text-primary w-6;

        @screen md {
            @apply hidden;
        }

        &:active, &:focus {
            @apply outline-none;
        }
    }

    .search-desktop, .filter-desktop {
        @apply hidden;

        @screen md {
            @apply block;
        }
    }

    .search-container {
        @apply mr-0;
    }

    .filter-mobile {
        @apply max-w-xs;
    }

    .td-after {
        @apply flex flex-row;

        & > * {
            @apply ml-2;
        }
    }
}
</style>
