<template>
    <div class="applications-digital-signature-modal">
        <ModalContainer :title="$t('applications.approve_application')" identifier="application-digital-signature-modal" :closable="true">
            <Form class="form" @submit="submit" :disabled="is_saving">

                <p>{{$t('applications.kindly_sign_below')}}</p>

                <div class="field-wrapper">
                    <div class="signature-container">
                        <vue-perfect-signature ref="signature" class="signature" :w="'320px'" :h="'150px'" :strokeOptions="strokeOptions" @onEnd="save"/>

                        <div class="buttons-container">
                            <button @click="clear">
                                <font-awesome-icon :icon="['fal','times']"/>
                            </button>
                        </div>
                    </div>

                    <div v-if="$v.signature.$error" class="form-errors">
                        <p v-if="!$v.signature.required">
                            {{$t('forms.field_required')}}
                        </p>
                    </div>
                </div>

                <Button type="submit" class="--primary --small" :class="{spinner: is_saving}">
                    {{$t('confirm')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
import ModalContainer from "../modal/ModalContainer";
import Form from "../form/Form";
import {required} from 'vuelidate/lib/validators'
import Button from "../Button";

export default {
    name: "ApplicationDigitalSignatureModal",
    components: {Button, Form, ModalContainer},
    data() {
        return {
            strokeOptions: {
                size: 2,
                thinning: 0.5,
                smoothing: 0.5,
                streamline: 0.5
            },
            signature: null,
            is_saving: false,
        }
    },
    validations: {
        signature: {required}
    },
    methods: {
        close(status) {
            this.$modal.hide('application-digital-signature-modal', status);
        },
        clear() {
            this.$refs.signature?.clear();
            this.signature = null;
        },
        save() {
            this.signature = this.$refs.signature?.toDataURL();
        },
        submit() {
            this.$v.signature.$touch();
            if (this.$v.signature.$anyError || this.is_saving)
                return;

            this.is_saving = true;

            console.log(this.signature);

            // this.$axios.patch(`applications/${this.application_id}`, payload).then(({data}) => {
            //     this.$notify({
            //         text: this.$t('applications.success_updated'),
            //         type: 'success',
            //     });
            //
            //     this.is_saving = false;
            //     this.close(true);
            // }).catch(e => {
            //     this.is_saving = false;
            //
            //     this.$notify({
            //         title: this.$t('error'),
            //         text: this.$larerror(e.response.data.errors, this.$t('applications.error_update')),
            //         type: 'error',
            //     });
            // });
        },
    },
}
</script>

<style lang="scss">
.applications-digital-signature-modal {
    p {
        @apply text-center;
    }

    .field-wrapper {
        @apply mx-auto;

        .form-errors {
            @apply my-1;

            p {
                @apply text-negative italic text-sm;
            }
        }
    }
}
</style>
