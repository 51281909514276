<template>
    <div class="applications-update-modal">
        <ModalContainer :title="$t('applications.update_status')" identifier="application-update-status-modal" :closable="true">
            <Form class="form" @submit="save" :disabled="is_loading_statuses || is_loading_original || is_saving">
                <FormInputSelect v-model="$v.application.status.$model" identifier="status"
                                 :label="$t('applications.status')" :options="status_options"
                                 :disabled="is_loading_original || is_loading_statuses || is_saving"
                                 :has-error="$v.application.status.$error"
                                 :display-custom-label="(row) => `${row.name}`">
                    <template v-slot:errors>
                        <p v-if="!$v.application.status.required">
                            {{$t('validation.x_is_required',{x: $t('applications.status')})}}
                        </p>
                    </template>
                </FormInputSelect>

                <FormInputText v-model="$v.application.message.$model" :label="$t('applications.message')"
                               :disabled="is_loading_original || is_saving" :use-textarea="true" :large-textarea="true"
                               :has-error="$v.application.message.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.application.message.required">
                            {{$t('validation.x_is_required',{x: $t('applications.message')})}}
                        </p>
                    </template>
                </FormInputText>

                <Button type="submit" className="--primary --small" :class="{spinner: is_saving}">
                    {{$t('save')}}
                </Button>
            </Form>
        </ModalContainer>
    </div>
</template>

<script>
    import _ from 'lodash';
    import ModalContainer from "../modal/ModalContainer";
    import Form from "../form/Form";
    import FormInputText from "../form/FormInputText";
    import FormInputSelect from "../form/FormInputSelect";
    import {required, email} from 'vuelidate/lib/validators'
    import Button from "../Button";
    import FormInputPassword from "@/components/form/FormInputPassword";

    export default {
        name: "ApplicationUpdateStatusModal",
        components: {FormInputPassword, Button, FormInputSelect, FormInputText, Form, ModalContainer},
        props: {
            application_id: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                original: null,
                application: {
                    status: null,
                    message: null,
                },
                status_options: [],
                is_saving: false,
                is_loading_original: false,
                is_loading_statuses: false,
            }
        },
        validations: {
            application: {
                status: {required},
                message: {required},
            }
        },
        methods: {
            close(status) {
                this.$modal.hide('application-update-status-modal', status);
            },
            async getStatuses() {
                this.is_loading_statuses = true;

                await this.$axios.get(`applications/list-statuses`)
                    .then(({data}) => {
                        this.status_options = data;

                        this.is_loading_statuses = false;
                    })
                    .catch(e => {
                        this.is_loading_statuses = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('applications.error_retrieve_statuses')),
                            type: 'error',
                        });
                    });
            },
            save() {
                this.$v.application.$touch();
                if (this.$v.application.$anyError || this.is_saving)
                    return;

                this.is_saving = true;

                let payload = {}

                if (this.original.attributes.status !== this.$v.application.status.$model)
                    payload.status = this.application.status.column;

                if (this.original.attributes.message !== this.$v.application.message.$model)
                    payload.message = this.application.message;

                this.$axios.patch(`applications/${this.application_id}`, payload).then(({data}) => {
                    this.$notify({
                        text: this.$t('applications.success_updated'),
                        type: 'success',
                    });

                    this.is_saving = false;
                    this.close(true);
                }).catch(e => {
                    this.is_saving = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('applications.error_update')),
                        type: 'error',
                    });
                });
            },
            getApplication() {
                this.is_loading_original = true;

                this.$axios.get(`applications/${this.application_id}`)
                    .then(({data}) => {
                        this.original = data.data;

                        this.application.status = _.find(this.status_options, {column: this.original.attributes.status});

                        this.is_loading_original = false;
                    })
                    .catch(e => {
                        this.is_loading_original = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$larerror(e.response.data, this.$t('applications.error_retrieve')),
                            type: 'error',
                        });
                    });
            },
        },
        async mounted() {
            await this.getStatuses();
            this.getApplication();
        }
    }
</script>
