<template>
    <div class="field">
        <p class="field-label">{{field.attributes.name}}</p>

        <div class="checkboxes-container">
            <div v-for="option in JSON.parse(field.attributes.options)" class="checkbox">
                <input type="checkbox" :id="option.name" v-model="check[option.name]" @change="answerQuestion" :disabled="disabled"/>
                <label :for="option.name">{{option.name}}</label>
            </div>

        </div>

        <div v-if="$v.check.$error" class="form-errors">
            <p v-if="!$v.check.requiredNotEmpty">{{$t('forms.select_at_least_one')}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Checkboxes",
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                check: {},
                is_answering_question: false
            }
        },
        validations: {
            check: {
                requiredNotEmpty: function (val) {
                    if (!val) return true;
                    if (!this.field.attributes.is_required) return true;

                    return Object.values(val).some(v => v === true);
                }
            },
        },
        methods: {
            checkFieldValid(){
                this.$v.$touch();
                return !this.$v.$anyError;
            },
            answerQuestion() {
                if (this.disabled) return;

                this.$v.$touch();
                if (this.$v.$anyError) return;

                let payload = {
                    value: this.check
                };

                this.is_answering_question = true;

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, payload)
                    .then(({data}) => {
                        this.is_answering_question = false;
                    })
                    .catch(e => {
                        this.is_answering_question = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_answer_question'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.attributes.value)
                this.check = this.field.relationships.answer.data.attributes.value;
        }
    }
</script>