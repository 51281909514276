<template>
    <div class="field">
        <p class="field-label" v-html="field.attributes.name"/>
    </div>
</template>

<script>
    export default {
        name: "TextBox",
        props: {
            field: {
                type: Object,
                required: true
            }
        },
        methods: {
            checkFieldValid(){
                return true;
            },
        },
    }
</script>