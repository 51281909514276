import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {i18n} from './locale/i18n';
import './css/app.scss';
import PrettyCheck from 'pretty-checkbox-vue/check';
import PrettyRadio from 'pretty-checkbox-vue/radio';
import VueHead from 'vue-head';
import Notifications from 'vue-notification';
import Vuelidate from 'vuelidate';
import Multiselect from 'vue-multiselect';
import VueGoodTablePlugin from 'vue-good-table';
import VModal from 'vue-js-modal';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueTippy, {TippyComponent} from "vue-tippy";
import PrettyCheckbox from 'pretty-checkbox-vue';
import VCalendar from 'v-calendar';
import { VueEditor } from "vue2-editor";
import VPerfectSignature from 'v-perfect-signature';
import VueInputTag from 'vue-input-tag';

Vue.prototype.$moment = require('moment-timezone');
Vue.prototype.$axios = require('axios');

Vue.prototype.$axios.defaults.headers['Content-Type'] = 'application/json';
Vue.prototype.$axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios.interceptors.request.use((config) => {
    if (store.getters.token)
        config.headers.common['Authorization'] = 'Bearer ' + store.getters.token.bearer;

    return config;
});

Vue.prototype.$axios.interceptors.response.use((res) => res, (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
        store.dispatch('logout');
        router.push({name: 'login'});
    }

    return Promise.reject(error);
});

Vue.prototype.$larerror = (error, fallback = 'error') => {
    if (!error)
        return fallback;

    if (error.errors && error.errors.constructor === Object)
        return error.errors[Object.keys(error.errors)[0]][0];
    else if (error.errors && error.errors.constructor === Array)
        return error.errors[0];

    if (error.message && error.message.constructor === String && error.message.length)
        return error.message;

    if (!error.errors && error.constructor === Object)
        return error[Object.keys(error)[0]][0];

    return error || fallback;
};

Vue.config.productionTip = false;

Vue.component('p-check', PrettyCheck);
Vue.component('p-radio', PrettyRadio);
Vue.use(VueHead);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.component('multiselect', Multiselect);
Vue.use(PrettyCheckbox);
Vue.component('vue-editor', VueEditor);
Vue.component('vue-perfect-signature', VPerfectSignature);
Vue.component('vue-input-tag', VueInputTag);

import 'vue-good-table/dist/vue-good-table.css';

Vue.use(VueGoodTablePlugin);
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);

Vue.use(VCalendar)

import "vue-multiselect/dist/vue-multiselect.min.css";

import {library} from '@fortawesome/fontawesome-svg-core';
import {} from '@fortawesome/free-solid-svg-icons';
import {
    faEye as farEye,
    faEyeSlash as farEyeSlash,
} from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown as farAngleDown,
    faSearch as farSearch,
    faTimes as farTimes,
    faUserCheck as farUserCheck,
    faUserSlash as farUserSlash,
    faCheck as farCheck, faArrowCircleRight, faArrowCircleLeft, faHomeLgAlt,
    faTimesCircle as farTimesCircle, faInfoCircle,
    faBookAlt as farBookAlt,
    faClipboardCheck as farClipboardCheck,
    faCar as farCar,
    faChartBar as farChartBar,
    faBuilding as farBuilding,
    faDoNotEnter as farDoNotEnter,
    faLongArrowLeft as farLongArrowLeft,
    faSync,
    faSackDollar,
    faCalendarDay,
    faSignature,
    faFileCertificate
} from '@fortawesome/pro-regular-svg-icons';
import {faUser} from '@fortawesome/pro-solid-svg-icons';
import {
    faUsersMedical as falUsersMedical,
    faBuilding as falBuilding,
    faClipboardList as falClipboardList,
    faUserFriends as falUserFriends,
    faFileChartLine as falFileChartLine,
    faPencil as falPencil,
    faTrash as falTrash,
    faEye as falEye,
    faFilePdf as falFilePdf,
    faFileExcel as falFileExcel,
    faLongArrowLeft,
    faCheckCircle,
    faBlinds,
    faListAlt,
    faKey,
    faBars,
    faTimesCircle,
    faBoothCurtain, faSlidersVSquare, faMinusCircle,
    faIconsAlt as falIconsAlt,
    faBookOpen as falBookOpen,
    faHouse as falHouse,
    faFileChartPie as falFileChartPie,
    faUpload as falUpload,
    faCalendar as falCalendar, faUserCheck, faBallotCheck, faDownload, faTimes as falTimes
} from '@fortawesome/pro-light-svg-icons';
import {} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText} from '@fortawesome/vue-fontawesome';
import {faShoppingCart} from "@fortawesome/pro-light-svg-icons/faShoppingCart";

library.add(farAngleDown, falUserFriends, falBuilding, falFileChartLine, falClipboardList, farEye, farEyeSlash,
    farSearch, falPencil, falTrash, farTimes, falFilePdf, falFileExcel, falUsersMedical, falEye, farUserCheck,
    farUserSlash, farCheck, faLongArrowLeft, faCheckCircle, faBlinds, faListAlt, faKey, faUser, faBars, faArrowCircleRight,
    faArrowCircleLeft, faHomeLgAlt, faTimesCircle, farTimesCircle, faCheckCircle, faInfoCircle, faShoppingCart, faBoothCurtain,
    faSlidersVSquare, faMinusCircle, farBookAlt, farClipboardCheck, farCar, farChartBar, falIconsAlt, falBookOpen, falHouse, farBuilding, falFileChartPie,
    falUpload, farDoNotEnter, falCalendar, faUserCheck, faBallotCheck, faSync, farLongArrowLeft, faDownload, faSackDollar, faCalendarDay, faSignature, falTimes,
    faFileCertificate);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
